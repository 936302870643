import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pages: {
        login:{
            login: 'Login',
            register: 'Register',
            noaccountyet: 'No account yet?',
            start_trial: 'Start a Trial now!',
        },
        dashboard:{
            request: 'Request Access',
            nomoduleavailable: 'No module available',
            performance: 'Performance',
        },
        performance:{
            github: {
                index: 'Github',
                notconfigured: 'PrimeFactor is not installed for any organization. You can configure it to get started.',
                install: 'Install',
                nodata: 'No data available, please check your configuration.',
                validate: 'Validate',
                active_developer: 'Active Developer',
                ide: 'IDE',
                language: 'Language',
                cost_of_writing: 'Cost of Writing Code',
                return_on_investment: 'Return on Investment',
                per_developer: '$ / developer',
                lines_accepted: ' lines accepted',
                acceptance_rate: 'Acceptance Rate',
                usage: {
                    title: 'Github Copilot Usage',
                    acceptances: 'Acceptances',
                    suggestions: 'Suggestions',
                    lines_suggested: 'Lines Suggested',
                    lines_accepted: 'Lines Accepted',
                    suggestion_percentage: 'Suggestions Percentage',
                    lines_percentage: 'Lines Percentage',
                    lines: 'Lines',
                    absolute: 'Absolute',
                    percentage: 'Percentage',
                },
                seats: 'Seats',
                clients: 'Clients',
                daterange: 'Please select a date range',
            }
        },
        settings: {
            title: 'Settings',
            githubsettings:{
                title: 'Github Settings',
                description_notinstalled: 'PrimeFactor is not installed for your organization. You can install it to get started.',
                description_installed: 'PrimeFactor is installed for your organization. You can uninstall it to stop using it.',
                refresh: 'Refresh',
                install: 'Install PrimeFactor',                
                uninstall: 'Uninstall PrimeFactor',
                organization: 'Actual Organization',
                no_organization: 'No organization are available',
                updatesuccedded: 'Update succeed',
                numberofusers: 'Number of seats',
                noseats: 'No seats available',
                error: 'An error occurred while updating the configuration. Please try again later.',
            },
            impersonation:{
                title: 'Impersonate User',
                description: 'You can impersonate a user to see the application as they see it. You can also impersonate a user to help them troubleshoot issues.',
                impersonate: 'Impersonate',
                email: 'Email',
                submit: 'impersonate',
                error: 'An error occurred while impersonating the user. Please try again later.',
            }
        },
        suggestion: {
            title: 'Suggestion',
            description: 'We value your input and appreciate your suggestions to help us improve our services. Your feedback is important to us!',
            messagetitle: 'Title',
            messagecontent: 'Message',
            submitting: 'Submitting...',
            thankyou: 'Thanks for your feedback!',
            error: "An error occurred while submitting the form. Please try again later."
        },
        requestaccess: {
            title: 'Request Access to ',
            description: 'You can add information to your request for access to new PrimeFactor modules. We will contact you shortly.',
            messagetitle: 'Module Name',
            messagecontent: 'Message',
            submitting: "Submitting...",
            thankyou: 'Request sent successfully! We will contact you shortly.',
            error: "An error occurred while submitting your request. Please try again later."
        },
        system: {
            wrapper: {
                owner: 'Owner',
                modifiedby: 'Modified by',
                createdby: 'Created by',
                on: 'on',
            } 
        }
    },
    menu: {        
        suggestion: 'Suggestion',
        settings: 'Settings',
        sysadmin:{
            index: 'System Administration',
            tenant: 'Tenants',
            user: 'Users',
            role: 'Roles',
            module: 'Modules',
            impersonate: 'Impersonate'            
        }
    },
    modules: {
        strategical: {
            index: 'Strategic',        
            strategicplanning: 'Strategic Planning',
            frameworktools: 'Strategic frameworks and tools',
        },
        tactical: {
            index: 'Tactical',
            estimation: 'Project Estimation',
            businesscase: 'Business Case',
            exploratoryanalysis: 'Exploratory Analysis',
        },
        operational: {
            index: 'Operational',
            governance: 'Governance',
            githubcopilotstats: 'Github Copilot Stats',
            prodevplan: 'Professional Development',            
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            overview: 'Overview',
            content: 'Content',
            exercice: 'Exercice',
            administration: 'Administration',
            present: 'Present',
            settings: 'Settings',
        },
        events: {
            review: {
                title: 'Posted review on "%{product}"',
            },
            order: {
                title: 'Ordered 1 poster |||| Ordered %{smart_count} posters',
            },
        },
    },
    resources: {
        client: {
            name: 'Client |||| Clients',
            fields: {
                commands: 'Client'                
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete Client',
            }
        },
        clientnote: {
            name: 'Client Note |||| Client Notes',
        },
        tool: {
            name: 'Tool |||| Tools',
            fields: {
                commands: 'Tools',
                first_seen: 'First seen',
                full_name: 'Name',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Tool',
            },
        },
        program: {
            name: 'Program |||| Programs',
            fields: {
                commands: 'Programs',
                full_name: 'Program'
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete Program',
            },
        },
        programprofile: {
            name: 'Program Profile |||| Program Profiles',
            fields: {
                commands: 'Program Profiles',
                full_name: 'Program Profile'
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete Program Profile',
            },
        },
        solutioncomparision: {
            name: 'Solution Comparision |||| Solution Comparisions',
            fields: {
                commands: 'Solution Comparisions',
                full_name: 'Solution Comparision'
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete Solution Comparision',
            },
        },
        systemtenant: {
            name: 'Tenant |||| Tenants',
            fields: {
                commands: 'Tenants',
                full_name: 'Tenant'
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete Tenant',
            },
        },
        systemuser: {
            name: 'System User |||| System Users',
            fields: {
                commands: 'System Users',
                full_name: 'System User'
            },
            filters: {                
                group: 'Segment',
            },
            fieldGroups: {
                
            },
            page: {
                delete: 'Delete System User',
            },
        },
    },
};

export default customEnglishMessages;
